import Icon, { CloseOutlined, RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Select, Tag, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Uploader from '@components/uploader';
import ZeissButton from '@components/button';
import { useImmer } from 'use-immer';
import CascaderList, { CascaderItem, Option } from '@components/cascader';
import UserSelect, {
  CustomTagProps,
  UserValue
} from './components/user-select';
import MessageSelect from './components/message-select';
import './upload.scss';
import {
  addContent,
  addLabel,
  getAuthList,
  getLabelList,
  getUploadCategoryList
} from '@api/home';
import { AuthListResult, FileParams, UserResult } from '@api/home/type';
import { CategoryListItem } from '@api/category/type';
import _ from 'lodash';
import ZeissModal from '@components/zeiss-modal';
import { ReactComponent as ImportAccountIcon } from '@/assets/image/import-account-icon.svg';
import type { UploadFile as UploadFileProps } from 'antd/es/upload/interface';

export interface FileListItem {
  fileName: string;
  type: 1 | 2;
  fileUrl: string;
  icon?: string;
  fileType?: string;
}

const UploadFile: FC = () => {
  const [search] = useSearchParams();
  const [cascaderList, setCascaderList] = useImmer<CascaderItem[]>([]);
  const [tagList, setTagList] = useState<{ id: string; name: string }[]>([]);
  const [userNeedMessage, setUserNeedMessage] = useImmer<UserValue[]>([]);
  const [selectedUsers, setSelectedUsers] = useImmer<UserValue[]>([]);
  const [selectedRoles, setSelectedRoles] = useImmer<
    (UserValue & { users: UserResult[] })[]
  >([]);
  const [fileList, setFileList] = useImmer<
    Array<UploadFileProps & { dataClassification: number }>
  >([]);
  const [users, setUsers] = useState<UserResult[]>([]);
  const [roles, setRoles] = useState<AuthListResult['roles']>([]);
  const [categoryList, setCategoryList] = useImmer<CategoryListItem[]>([]);
  const [selectedTags, setSelectedTags] = useState<
    { id: string; name: string }[]
  >([]);
  const [selectUserAll, setSelectUserAll] = useState<boolean>(false);
  const [selectMessageAll, setSelectMessageAll] = useState<boolean>(false);
  const [tipsRoles, setTipsRoles] = useImmer<
    (UserValue & { users: UserResult[] })[]
  >([]);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const getCategories = async () => {
    try {
      const { children } = await getUploadCategoryList({
        rootId: '0',
        path: '/'
      });
      setCategoryList(() => children);
    } catch (error) {}
  };

  const getUsersAndRoles = async () => {
    try {
      const data = await getAuthList();
      setUsers(data.users);
      setRoles(
        data.roles.map((item) => ({ ...item, users: item.users || [] }))
      );
    } catch (error) {}
  };

  const getLabels = async (name?: string) => {
    try {
      const data = await getLabelList({ name });
      setTagList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getUsersAndRoles();
    getCategories();
    getLabels();
  }, []);

  const handleTagCloseClick = (props: CustomTagProps) => {
    setSelectedTags(selectedTags.filter((tag) => tag.name !== props.label));
  };

  const tagRender = (props: CustomTagProps) => {
    return (
      <Tag className={'custom-tag'}>
        {props.label}
        <CloseOutlined
          className="custom-tag-icon"
          onClick={() => handleTagCloseClick(props)}
        />
      </Tag>
    );
  };

  const handleSelectChange = async (
    items: { value: string; label: string }[]
  ) => {
    setSelectedTags(
      items.map((item) => ({ id: item.value, name: item.label || item.value }))
    );
  };

  const handleUpload = async () => {
    if (!fileList?.length) {
      message.error('请先上传文件');
      return;
    }
    let emails: string[] = [];
    if (userNeedMessage.filter((item) => item.value === 'all').length) {
      emails = users.map((user) => user.email || '');
    } else {
      for (let i = 0; i < userNeedMessage.length; i++) {
        const email = users.find(
          (user) => user.userId === userNeedMessage[i].value
        )?.email;
        if (email) {
          emails.push(email);
        }
      }
    }
    const categoryIds: string[] = [];
    for (let i = 0; i < cascaderList.length; i++) {
      if (cascaderList[i].value.length < 2) {
        message.error('不可在一级分类下上传文件，请检查分类选择');
        return;
      }
      `${cascaderList[i].value}`
        .split(',')
        .forEach((item) => categoryIds.push(item));
    }
    if (!categoryIds.length) {
      message.error('请先选择分类');
      return;
    }

    if (!selectedUsers.length && !selectedRoles.length) {
      message.error('请先选择有效访问用户');
      return;
    }

    const rolesWithNoUser: (UserValue & { users: UserResult[] })[] = [];
    selectedRoles.forEach((role) => {
      if (!role.users?.length) {
        rolesWithNoUser.push(role);
      }
    });
    if (rolesWithNoUser.length) {
      setTipsRoles(rolesWithNoUser);
      setOpen(true);
      return;
    }

    const newTags = [];
    for (let i = 0; i < selectedTags.length; i++) {
      const element = selectedTags[i];
      if (
        tagList.findIndex((tag) => tag.name === element.name.toString()) < 0
      ) {
        newTags.push(element);
      }
    }
    await Promise.all(
      newTags.map(async (tag) => {
        await addLabel({ name: tag.name });
      })
    );

    const result = {
      fileList: fileList.map((file) => ({
        fileName: file.name,
        type: 2 as FileParams['type'],
        fileUrl: file.response
          ? file.response.includes('&comp=blocklist')
            ? file.response.replace('&comp=blocklist', '')
            : file.response
          : '',
        icon: file.thumbUrl,
        dataClassification: file.dataClassification
      })),
      labels: selectedTags?.map((tag) => tag.name).join(','),
      fileParentIds: cascaderList?.map(
        (item) => `${item.value[item.value.length - 1]}`
      ),
      categoryIds,
      userIds: selectedUsers?.filter((item) => item.value === 'all').length
        ? users?.map((user) => user.userId)
        : _.uniqBy(_.compact(selectedUsers?.map((user) => user.value))),
      roles: selectedRoles?.map((role) => ({
        ...role,
        roleId: role.value
      })),
      emails
    };
    try {
      await addContent(result);
      message.success('上传成功');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAfterUpload = (uid: string, iconUrl: string) => {
    setFileList((draft) => {
      const index = draft.findIndex((item) => item.uid === uid);
      if (draft[index]) {
        draft[index].thumbUrl = iconUrl;
        draft[index].dataClassification = 0;
      }
      return draft;
    });
  };

  return (
    <div className="wrapper">
      <Breadcrumb
        items={search
          .get('path')
          ?.split('/')
          .map((item) => ({ title: item }))}
        separator={<RightOutlined />}
      />
      <div className="content-upload-wrapper">
        <div className="page-subtitle">上传内容</div>
        <Uploader
          fileList={fileList}
          onChange={setFileList}
          afterUpload={handleAfterUpload}
        >
          <div className="upload-container">
            <p className="ant-upload-drag-icon">
              <Icon component={ImportAccountIcon} style={{ fontSize: 48 }} />
            </p>
            <p className="account-upload-text">{'上传文件'}</p>
            <p className="ant-upload-hint">or drag and drop it here</p>
          </div>
        </Uploader>
      </div>
      <div className="category-chosen-wrapper">
        <div className="page-subtitle">选择分类</div>
        <CascaderList
          options={categoryList}
          cascaderList={cascaderList}
          setCascaderList={setCascaderList}
        />
        <div className="upload-select-wrapper">
          <p>内容标签</p>
          <Select
            labelInValue
            className="upload-select"
            mode="tags"
            style={{ width: '100%' }}
            placeholder="选择标签"
            onChange={handleSelectChange}
            bordered={false}
            options={tagList.map((tag) => ({ value: tag.id, label: tag.name }))}
            showSearch
            optionFilterProp="name"
            tagRender={tagRender}
            value={_.cloneDeep(
              selectedTags.map((tag) => ({ value: tag.id, label: tag.name }))
            )}
          />
        </div>
      </div>
      <div className="upload-user-wrapper">
        <div className="page-subtitle">用户及通知设置</div>
        <div className="user-message-wrapper">
          <div className="user-select-wrapper">
            <div>有效访问用户</div>
            <UserSelect
              roles={roles}
              users={users}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              selectedRoles={selectedRoles}
              setSelectedRoles={setSelectedRoles}
              userNeedMessage={userNeedMessage}
              setUserNeedMessage={setUserNeedMessage}
              selectUserAll={selectUserAll}
              setSelectUserAll={setSelectUserAll}
              selectMessageAll={selectMessageAll}
              setSelectMessageAll={setSelectMessageAll}
            />
          </div>
          <div className="message-select-wrapper">
            <div>同时通知</div>
            <MessageSelect
              options={
                selectUserAll
                  ? [{ value: 'all', label: '所有用户' }].concat(
                      users.map((user) => ({
                        value: user.userId,
                        label: user.username
                      }))
                    )
                  : _.uniqBy(
                      selectedUsers.concat(
                        _.flatten(selectedRoles.map((role) => role.users))
                      ),
                      'userId'
                    ).map((item: UserResult) => ({
                      value: item?.userId,
                      label: item?.username
                    }))
              }
              userNeedMessage={userNeedMessage}
              setUserNeedMessage={setUserNeedMessage}
              setSelectMessageAll={setSelectMessageAll}
              selectedUsers={selectedUsers}
              selectedRoles={selectedRoles}
              selectUserAll={selectUserAll}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <ZeissButton
          className="upload-button"
          type="primary"
          onClick={handleUpload}
        >
          确定
        </ZeissButton>
        <ZeissButton className="upload-button" onClick={() => navigate(-1)}>
          取消
        </ZeissButton>
      </div>
      <ZeissModal
        open={open}
        footer={
          <ZeissButton
            type="primary"
            className="color-btn"
            onClick={() => setOpen(false)}
          >
            知道了
          </ZeissButton>
        }
      >
        角色{tipsRoles.map((item) => item.label).join('、')}中未分配用户
      </ZeissModal>
    </div>
  );
};

export default UploadFile;

import { IS_PROD } from '@constants';
import { ReactNode, useEffect } from 'react';
import sensors from 'sa-sdk-javascript';

const AppSensors = ({ children }: { children: ReactNode }): any => {
  // 埋点
  useEffect(() => {
    sensors.init({
      server_url: process.env.REACT_APP_BASE_SA,
      is_track_single_page: true,
      use_client_time: true,
      send_type: 'beacon',
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'default',
        get_vtrack_config: true,
        collect_url: true,
        element_selector: 'not_use_id'
      },
      show_log: IS_PROD
    });
    sensors.registerPage({
      platform_type: 'PC', // 公众坷，h5 都填写 H5
      project: 'iqs-dmms', // 项目名称
      pageArea: 'web', // 项目类型
      pageCountry: 'China', // 所在地区
      pageLanguage: 'zh-cn', // 语言
      pageIdentifier: 'iqs-dmms', // 项目名称
      pagePool: 'ZDP' // 部门
    });
    sensors.quick('autoTrack');
  }, []);

  return <>{children}</>;
};

export default AppSensors;

import { FC, useEffect, useMemo, useState } from 'react';
import { CustomTagProps, UserValue } from './user-select';
import { Avatar, Checkbox, Input, Select, Tag } from 'antd';
import { Updater, useImmer } from 'use-immer';
import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';

interface MessageSelectProps {
  options: UserValue[];
  userNeedMessage: UserValue[];
  setUserNeedMessage: Updater<UserValue[]>;
  setSelectMessageAll: (value: boolean) => void;
  selectedRoles: UserValue[];
  selectedUsers: UserValue[];
  selectUserAll: boolean;
}

const MessageSelect: FC<MessageSelectProps> = ({
  options = [],
  userNeedMessage = [],
  setUserNeedMessage,
  setSelectMessageAll,
  selectedUsers,
  selectedRoles,
  selectUserAll
}) => {
  const handleDeleteItem = (item: UserValue) => {
    if (item.value === 'all') {
      setSelectMessageAll(false);
    }
    setUserNeedMessage((draft) =>
      draft.filter((user) => user.value !== item.value)
    );
  };

  const tagRender = (props: CustomTagProps) => {
    return (
      props.value && (
        // id长度小于19是角色id，19位是用户id
        <Tag
          className={`custom-tag ${props.value.length < 10 ? 'role-tag ' : ''}`}
        >
          {props.label}
          <CloseOutlined
            className="custom-tag-icon"
            onClick={() =>
              handleDeleteItem({
                label: props.label as string,
                value: props.value
              })
            }
          />
        </Tag>
      )
    );
  };

  const handleSelectAllChange = () => {
    if (userNeedMessage.length === options.length) {
      setUserNeedMessage([]);
    } else {
      setUserNeedMessage(options);
    }
  };

  // const getSelectAllChecked = () => {
  //   if (userNeedMessage.find((user) => user.value === 'all')) {
  //     return (
  //       !!userNeedMessage.length &&
  //       userNeedMessage.length === options.length + 1
  //     );
  //   } else {
  //     !!userNeedMessage.length && userNeedMessage.length === options.length;
  //   }
  // };

  // const getSelectAllIndeterminate = () => {
  //   if (userNeedMessage.find((user) => user.value === 'all')) {
  //     return (
  //       userNeedMessage.length > 0 &&
  //       userNeedMessage.length < options.length + 1
  //     );
  //   } else {
  //     userNeedMessage.length > 0 && userNeedMessage.length < options.length;
  //   }
  // };

  return (
    <div className="select-content messaged-user">
      <Select
        allowClear
        className="user-select"
        labelInValue
        mode="multiple"
        options={options}
        bordered={false}
        onChange={(newValue: UserValue[]) => {
          if (!newValue.filter((value) => value.value === 'all').length) {
            setSelectMessageAll(false);
          }
          setUserNeedMessage(newValue);
        }}
        value={_.cloneDeep(userNeedMessage)}
        placeholder={'请输入'}
        showSearch
        optionFilterProp="label"
        tagRender={tagRender}
      />
      <div className="messaged-user-wrapper">
        <div
          key={`message-selectAll`}
          className="user-need-message-item user-message-select-all"
        >
          <Checkbox
            onChange={handleSelectAllChange}
            checked={
              !!userNeedMessage.length &&
              userNeedMessage.length === options.length
            }
            indeterminate={
              userNeedMessage.length > 0 &&
              userNeedMessage.length < options.length
            }
          >
            全选
          </Checkbox>
        </div>
        {userNeedMessage
          .filter((item) => item.value)
          .map((item: UserValue) => (
            <div
              key={`message-${item.value}`}
              className="user-need-message-item"
            >
              <div className="user-info">
                <Avatar style={{ backgroundColor: '#0072EF' }}>
                  {item?.label?.split('')[0]}
                </Avatar>
                <span className="user-name">{item.label}</span>
              </div>
              <CloseOutlined
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteItem(item)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MessageSelect;

import { Key, useEffect, useState } from 'react';
import UserDetailTable from './component/user-detail-table';
import { TableProps } from 'antd';
import { useImmer } from 'use-immer';
import { listResponseRecordsFace } from '@api/user/type';
import { getUserList } from '@api/user';
import UserEdit from './user-edit';

const OutsideUserTable = () => {
  const [users, setUsers] = useImmer<listResponseRecordsFace[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [editUser, setEditUser] = useImmer<listResponseRecordsFace[]>([]);
  const [pagination, setPagination] = useState({
    sizeCanChange: true,
    total: 0,
    pageSize: 10,
    current: 1,
    showQuickJumper: true,
    showSizeChanger: true
  });

  const handleTableChange: TableProps<listResponseRecordsFace>['onChange'] =
    async (paginationConfig, filters) => {
      setPagination({
        ...pagination,
        pageSize: paginationConfig.pageSize || 10,
        current: paginationConfig.current || 1
      });
      await getOutsideUsers(
        paginationConfig.current,
        paginationConfig.pageSize,
        searchValue,
        ((filters.role as Key[]) || []).map((item) => +(item as number))
      );
    };

  const getOutsideUsers = async (
    pageNumber?: number,
    pageSize?: number,
    searchValue?: string,
    roleIdList?: number[]
  ) => {
    const { users } = await getUserList({
      pageNumber,
      pageSize,
      type: 1,
      keyword: searchValue,
      roleIdList
    });
    setUsers(users.records || []);
    setPagination((draft) => ({
      ...draft,
      current: users.current,
      pageSize: users.size,
      total: users.total
    }));
  };

  useEffect(() => {
    getOutsideUsers();
  }, []);

  useEffect(() => {
    getOutsideUsers(1, pagination.pageSize, searchValue);
  }, [searchValue]);

  return editUser.length ? (
    <UserEdit
      editUser={editUser}
      setEditUser={setEditUser}
      fetchUsers={getOutsideUsers}
    />
  ) : (
    <>
      <div className="page-title">外部用户</div>
      <UserDetailTable
        data={users}
        pagination={pagination}
        handleTableChange={handleTableChange}
        handleSearch={(searchValue) => setSearchValue(searchValue)}
        setEditUser={setEditUser}
      />
    </>
  );
};

export default OutsideUserTable;

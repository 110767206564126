import { Dispatch, FC, Key, SetStateAction, useEffect } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  MenuProps,
  Popover,
  Table,
  Tag,
  message
} from 'antd';
import { useMemo, useState } from 'react';
import Icon, { DownOutlined, UpOutlined } from '@ant-design/icons';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { ReactComponent as SortIcon } from '@/assets/image/sort-icon.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CategoryListItem } from '@api/category/type';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { selectedFile, userInfo } from '@store';
import './index.scss';
import ZeissButton from '@components/button';
import { ReactComponent as MoreIcon } from '@/assets/image/more.svg';
import { deleteFile, deleteFileMapping, downloadRecord } from '@api/home';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import download from '@utils/download';
import { UserInfoAuthority } from '@utils/tool';
import { getTagColorIndex, tagsColors } from '../content';
import ClassTypeTag from '@components/classTypeTag';

interface ContentTableProps {
  list: CategoryListItem[];
  multiple: boolean;
  pagination: TablePaginationConfig;
  setPagination: (pagination: TablePaginationConfig) => void;
  fetchData: (
    paginationConfig?: {
      current?: number;
      pageSize?: number;
    },
    filters?: Record<string, FilterValue | null>,
    sorter?: SorterResult<CategoryListItem> | SorterResult<CategoryListItem>[]
  ) => Promise<void>;
  expandedRowKeys: string[];
  setExpandedRowKeys: (value: string[]) => void;
  loading?: boolean;
  updateDataClassification: (value: string[]) => void;
}

const ContentTable: FC<ContentTableProps> = ({
  list,
  multiple,
  pagination,
  setPagination,
  fetchData,
  expandedRowKeys,
  setExpandedRowKeys,
  loading,
  updateDataClassification
}) => {
  const [userInfos, setUserInfo] = useRecoilState<{
    authority: UserInfoAuthority[];
  }>(userInfo);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);
  const navigate = useNavigate();

  const handleTableChange: TableProps<CategoryListItem>['onChange'] = async (
    paginationConfig,
    filters,
    sorter
  ) => {
    setPagination({
      ...pagination,
      pageSize: paginationConfig.pageSize || 10,
      current: paginationConfig.current || 1
    });
    await fetchData(
      {
        current: paginationConfig.current,
        pageSize: paginationConfig.pageSize
      },
      filters,
      sorter
    );
  };

  const handleDownLoad = async (record: CategoryListItem) => {
    // saveAs(record.item.fileUrl as string, record.item.name);
    if (!record.item.fileUrl) return;
    download(record.name, record.item.fileUrl);
    // message.success('后台下载中，请稍候');
    await downloadRecord({ fileIds: _.compact([record.item.fileId]) });
    await fetchData();
    setSelectedFileList([]);
  };
  // 编辑数据分级
  const handleEditDataClassification = async (record: CategoryListItem) => {
    updateDataClassification([record.item.fileId!]);
  };

  const handleDeleteInCategory = async (record: CategoryListItem) => {
    try {
      await deleteFileMapping({
        categoryIds: [record.id]
      });
      await fetchData({ current: 1 });
      // await fetchNavigator?.();
      setSelectedFileList([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAll = async (record: CategoryListItem) => {
    try {
      await deleteFile({
        fileIds: _.compact([record.item.fileId])
      });
      await fetchData({ current: 1 });
      // await fetchNavigator?.();
      setSelectedFileList([]);
    } catch (error) {
      console.error(error);
    }
  };

  const hasAuth = (key: string) => {
    const auth = userInfos.authority
      .find((auth) => auth.resourceKey === 'content')
      ?.children?.find((item) => item.resourceKey === 'contentColumn')
      ?.children?.findIndex((child) => child.resourceKey === key);
    return auth !== undefined ? auth > -1 : false;
  };

  const hasActionAuth = (key: string) => {
    const auth = userInfos.authority
      .find((auth) => auth.resourceKey === 'content')
      ?.children?.findIndex((child) => child.resourceKey === key);
    return auth !== undefined ? auth > -1 : false;
  };

  const getAllCategory = (
    category: CategoryListItem,
    result: CategoryListItem[]
  ) => {
    if (category?.children?.length) {
      category.children.forEach((child) => {
        result.push(child);
        if (child.children) {
          getAllCategory(child, result);
        }
      });
    }
  };

  const getMoreAction = (record: CategoryListItem) => {
    const actionList: MenuProps['items'] = [];
    if (hasActionAuth('update_data_classification')) {
      actionList.push({
        label: (
          <div onClick={() => handleEditDataClassification(record)}>
            编辑数据分级
          </div>
        ),
        key: 'edit-data-classification'
      });
    }
    if (hasActionAuth('download')) {
      actionList.push({
        label: <div onClick={() => handleDownLoad(record)}>下载</div>,
        key: 'download'
      });
    }
    if (hasActionAuth('share')) {
      actionList.push({
        label: <div onClick={() => goShare(record)}>分享</div>,
        key: 'share'
      });
    }
    if (hasActionAuth('update_category')) {
      actionList.push({
        label: <div onClick={() => goEditCategory(record)}>编辑分类</div>,
        key: 'edit-category'
      });
    }
    if (hasActionAuth('update_permission')) {
      actionList.push({
        label: <div onClick={() => goEditPermission(record)}>编辑权限</div>,
        key: 'edit-permission'
      });
    }
    if (hasActionAuth('update_tag')) {
      actionList.push({
        label: <div onClick={() => goEditTags(record)}>编辑标签</div>,
        key: 'edit-tag'
      });
    }
    if (hasActionAuth('delete')) {
      actionList.push({
        label: (
          <div
            style={{ color: 'red' }}
            onClick={() => handleDeleteInCategory(record)}
          >
            当前分类中删除
          </div>
        ),
        key: 'delete'
      });
    }
    if (hasActionAuth('delete')) {
      actionList.push({
        label: (
          <div style={{ color: 'red' }} onClick={() => handleDeleteAll(record)}>
            所有分类中删除
          </div>
        ),
        key: 'deleteAll'
      });
    }
    return actionList;
  };

  const goShare = (record: CategoryListItem) => {
    navigate(`/contents/share`, {
      state: {
        fileList: [
          {
            fileId: record.item.fileId,
            fileName: record.name,
            fileUrl: record.item.fileUrl?.replace('&comp=blocklist', '')
          }
        ]
      }
    });
  };

  const goEditCategory = (record: CategoryListItem) => {
    navigate(`/contents/edit-category`, {
      state: {
        fileList: [
          {
            fileId: record.item.fileId,
            fileName: record.name,
            fileUrl: record.item.fileUrl
          }
        ]
      }
    });
  };

  const goEditPermission = (record: CategoryListItem) => {
    navigate(`/contents/edit-permission`, {
      state: {
        fileList: [
          {
            fileId: record.item.fileId,
            fileName: record.name,
            fileUrl: record.item.fileUrl
          }
        ]
      }
    });
  };

  const goEditTags = (record: CategoryListItem) => {
    navigate(`/contents/edit-tags`, {
      state: {
        fileList: [
          {
            fileId: record.item.fileId,
            labels: record.item.labels
          }
        ]
      }
    });
  };

  function findItemsWithSuffix(node: CategoryListItem) {
    let result: CategoryListItem[] = [];

    if (node.item && node.item.suffix !== null) {
      result.push(node);
    }

    if (node.children) {
      for (const child of node.children) {
        result = result.concat(findItemsWithSuffix(child));
      }
    }

    return result;
  }

  const extraProps = multiple
    ? ({
      rowSelection: {
        selectedRowKeys: _.cloneDeep(selectedFileList).map(
          (item: CategoryListItem) => item.id
        ),
        onSelect: (record, selected, selectedRows) => {
          if (selected) {
            setSelectedFileList((draft) => {
              let temp: CategoryListItem[] = _.cloneDeep(draft);
              const newList = selectedRows.filter((item) => item.item.suffix);
              return _.uniqBy(temp.concat(newList), 'id');
            });
          } else {
            if (record.item.suffix) {
              setSelectedFileList((draft) =>
                draft.filter((item) => item.item.id !== record.item.id)
              );
            } else {
              const files: CategoryListItem[] = findItemsWithSuffix(record);
              setSelectedFileList((draft) => {
                const result = draft.filter(
                  (item) =>
                    !files.find((file) => file.item.id === item.item.id)
                );
                return result;
              });
            }
          }
        },
        onSelectAll(selected, selectedRows, changeRows) {
          if (selected) {
            setSelectedFileList((draft) => {
              let temp: CategoryListItem[] = _.cloneDeep(draft);
              const newList = selectedRows.filter((item) => item.item.suffix);
              return _.uniqBy(temp.concat(newList), 'id');
            });
          } else {
            let files: CategoryListItem[] = [];
            changeRows.forEach((changed) => {
              files = files.concat(findItemsWithSuffix(changed));
            });
            setSelectedFileList((draft) => {
              const result = draft.filter(
                (item) => !files.find((file) => file.item.id === item.item.id)
              );
              return result;
            });
          }
        },
        checkStrictly: false
      }
    } as TableProps<CategoryListItem>)
    : {};

  const getColumns = () => {
    const columns: ColumnsType<CategoryListItem> = [];
    if (hasAuth('file_name')) {
      columns.push({
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        ellipsis: true
      });
    }
    if (hasAuth('upload_time')) {
      columns.push({
        title: '上传时间',
        dataIndex: 'createTime',
        key: 'createTime',
        sorter: true,
        sortIcon: () => <Icon component={SortIcon} />,
        render: (_: unknown, record: CategoryListItem) => {
          return (
            (record.item.type === 2 || record.item.suffix) &&
            dayjs(record.item.createTime).format('YYYY-MM-DD HH:mm:ss')
          );
        }
      });
    }
    if (hasAuth('type')) {
      columns.push({
        title: '类型',
        dataIndex: 'suffix',
        key: 'suffix',
        sorter: true,
        sortIcon: () => <Icon component={SortIcon} />,
        render: (_: unknown, record: CategoryListItem) => record.item.suffix
      });
    }
    if (hasAuth('upload_user')) {
      columns.push({
        title: '上传者',
        dataIndex: 'item',
        render: (item: CategoryListItem['item']) => <>{item.createUser}</>
      });
    }
    if (hasAuth('tag')) {
      columns.push({
        title: '标签',
        dataIndex: 'tag',
        key: 'tag',
        render: (_: unknown, record: CategoryListItem) => {
          const tagList = record.item.labels?.split(',');
          if (tagList?.length > 3) {
            return (
              <Popover
                content={tagList.map((label, index) => (
                  <Tag
                    key={label + index}
                    bordered={false}
                    color={tagsColors[getTagColorIndex()]}
                    className="table-tag"
                  >
                    {label}
                  </Tag>
                ))}
              >
                {tagList.splice(0, 3).map((label, index) => (
                  <Tag
                    key={label + index}
                    bordered={false}
                    color={tagsColors[getTagColorIndex()]}
                    className="table-tag"
                  >
                    {label}
                  </Tag>
                ))}
                ...
              </Popover>
            );
          }
          return tagList?.map((label, index) => (
            <Tag
              key={label + index}
              bordered={false}
              color={tagsColors[getTagColorIndex()]}
              className="table-tag"
            >
              {label}
            </Tag>
          ));
        }
      });
    }
    if (hasAuth('data_classification')) {
      columns.push({
        title: '数据分级',
        dataIndex: 'dataClassification',
        key: 'dataClassification',
        render: (_: unknown, record: CategoryListItem) =>
          record.item.type === 2 ? (
            <ClassTypeTag
              value={record.item.dataClassification || 0}
              icon={false}
            />
          ) : null
      });
    }
    if (hasAuth('click_volume')) {
      columns.push({
        title: '下载量',
        dataIndex: 'downLoadNum',
        key: 'downLoadNum',
        render: (_: unknown, record: CategoryListItem) =>
          record.item.fileUrl && `${record.item.downLoadNum}`
      });
    }
    columns.push({
      title: '操作',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: unknown, record: CategoryListItem) => (
        <div className="table-actions">
          {record.item.type !== 1 && hasAuth('detail') && (
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                navigate(`/contents/detail/${record.item.fileId}`, {
                  state: { from: window.location.pathname }
                });
              }}
            >
              详情
            </Button>
          )}
          {record.item.type !== 1 && !!getMoreAction(record).length && (
            <>
              <Divider type="vertical" />
              <Dropdown menu={{ items: getMoreAction(record) }}>
                <Button type="link" style={{ padding: 0 }}>
                  更多
                  <Icon component={MoreIcon} />
                </Button>
              </Dropdown>
            </>
          )}
        </div>
      )
    });

    return columns;
  };

  const handleExpanedRowsChange = (expandedKeys: readonly Key[]) => {
    setExpandedRowKeys(expandedKeys as string[]);
  };

  return (
    <>
      <Table
        className="content-table"
        rowKey={(record) => record.id}
        dataSource={list}
        columns={getColumns()}
        loading={loading}
        pagination={{
          ...pagination,
          showTotal: () => `Total ${pagination.total} items`
        }}
        onChange={handleTableChange}
        size="small"
        expandable={{
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: handleExpanedRowsChange
        }}
        {...extraProps}
      ></Table>
    </>
  );
};

export default ContentTable;

import Icon from '@ant-design/icons';
import { Spin, Table, Upload, UploadProps, message } from 'antd';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import './index.scss';
import { uploadFile } from '@api/user';
import { saveAs } from 'file-saver';
import ZeissBreadcrumb from '@components/breadcrumb';
import ZeissButton from '@components/button';
import { ReactComponent as ImportAccountIcon } from '@/assets/image/import-account-icon.svg';
import type { UploadRequestOption, RcFile } from 'rc-upload/lib/interface';
import { useImmer } from 'use-immer';
import { ErrorAccountResult } from '@api/user/type';

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const DOWNLOAD_URL =
  'https://czcn57r01sa001p.blob.core.chinacloudapi.cn/iqs-dmms-bff-service/xlsx/iqs-dmms-bff-service/20231027/%E5%AF%BC%E5%85%A5%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx?sp=racwl&st=2024-11-29T03:39:30Z&se=2099-11-29T11:39:30Z&spr=https&sv=2022-11-02&sr=c&sig=ssEfo%2FsbSuIvfE2JgSy%2FDcLU7y%2Bpxryrce3Mmo3tfy0%3D';

const BulkCreateAccount: FC = () => {
  const { Dragger } = Upload;
  // const navigate = useNavigate();
  const [errorList, setErrorList] = useImmer<ErrorAccountResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadClick = () => {
    saveAs(DOWNLOAD_URL, '导入名单模板.xlsx');
  };

  // const handleClickCancel = () => {
  //   navigate({ pathname: `/account-list` });
  // };

  const handleUploadFile = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const { data } = await uploadFile({ formData });
      if (data.data.length) {
        setErrorList(data.data);
        message.error(
          `${(file as RcFile).name}文件上传成功，其中包含解析失败的账户`
        );
      } else {
        message.success(`${(file as RcFile).name}文件上传成功`);
      }
    } catch {
      message.error(`${(file as RcFile).name}文件上传失败`);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      key: 'nickName',
      title: t('account.username'),
      dataIndex: 'nickName'
    },
    {
      key: 'account',
      title: t('pages.user.Email'),
      dataIndex: 'account'
    },
    {
      key: 'errMsg',
      title: t('account.errMsg'),
      dataIndex: 'errMsg'
    }
  ];

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    customRequest: handleUploadFile,
    accept:
      '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    showUploadList: false,
    beforeUpload: (file) => {
      if (file.size > MAX_FILE_SIZE) {
        message.error('超出文件大小限制');
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  };

  const renderErrorTable = useMemo(
    () =>
      !!errorList.length && (
        <div className="table-wrapper">
          <div className="page-subtitle">{t('account.errMsg')}</div>
          <Table
            dataSource={errorList}
            columns={columns}
            pagination={false}
            size="small"
          />
        </div>
      ),
    [errorList]
  );

  return (
    <div className="bulk-create-wrapper wrapper">
      <ZeissBreadcrumb />
      <div className="content-wrapper">
        <div className="upload-header">
          <div className="page-subtitle">{t('account.importAccount')}</div>
          <ZeissButton onClick={handleDownloadClick} className="color-btn">
            下载模板
          </ZeissButton>
        </div>
        <div className="bulk-create-dragger-wrapper">
          <Spin spinning={loading}>
            <Dragger {...props} className="bulk-create-upload">
              <p className="ant-upload-drag-icon">
                <Icon component={ImportAccountIcon} style={{ fontSize: 40 }} />
              </p>
              <p className="account-upload-text">
                {t('account.importAccount')}
              </p>
              <p className="ant-upload-hint">
                or drag and drop it here（max. 10MB）
              </p>
            </Dragger>
          </Spin>
        </div>
        {renderErrorTable}
      </div>
    </div>
  );
};

export default BulkCreateAccount;

import { FC } from 'react';
import {
  Breadcrumb,
  Divider,
  Dropdown,
  MenuProps,
  Popover,
  Space,
  TablePaginationConfig,
  Tooltip,
  message
} from 'antd';
import Icon, { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import ZeissButton from '@components/button';
import { ReactComponent as ChecklistIcon } from '@/assets/image/checklist-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/image/delete-icon.svg';
import { ReactComponent as UploadIcon } from '@/assets/image/upload.svg';
import { ReactComponent as MoreIcon } from '@/assets/image/more.svg';
import { ReactComponent as DashboardIcon } from '@/assets/image/dashboard.svg';
import { ReactComponent as DownIcon } from '@/assets/image/download.svg';
import { ReactComponent as ExpandIcon } from '@/assets/image/expand.svg';
import { ReactComponent as CollapseIcon } from '@/assets/image/collapse.svg';
import { selectedFile, userInfo } from '@store';
import { useRecoilState } from 'recoil';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteFile,
  deleteFileMapping,
  downloadRecord,
  getDownloadZip
} from '@api/home';
import { CategoryListItem } from '@api/category/type';
import _ from 'lodash';
import './index.scss';
import download from '@utils/download';
import { UserInfoAuthority, addTreeKeyToArray, hasAuth } from '@utils/tool';

interface ContentHeaderProps {
  list: CategoryListItem[];
  viewType: 'list' | 'view';
  setViewType: (value: 'list' | 'view') => void;
  multiple: boolean;
  setMultiple: (value: boolean) => void;
  fetchData: (pagination?: TablePaginationConfig) => Promise<void>;
  fetchNavigator?: () => Promise<void>;
  breadcrumb?: { paths: string; pathNames: string };
  expandedRowKeys: string[];
  setExpandedRowKeys: (value: string[]) => void;
  updateDataClassification: (value: string[]) => void;
}

const ContentHeader: FC<ContentHeaderProps> = ({
  list,
  viewType,
  setViewType,
  multiple,
  setMultiple,
  fetchData,
  fetchNavigator,
  breadcrumb,
  expandedRowKeys,
  setExpandedRowKeys,
  updateDataClassification
}) => {
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [userInfos, setUserInfo] = useRecoilState<{
    authority: UserInfoAuthority[];
  }>(userInfo);
  const [selectedFileList, setSelectedFileList] =
    useRecoilState<CategoryListItem[]>(selectedFile);
  const { pathname } = useLocation();

  const goShare = () => {
    if (!selectedFileList.length) {
      message.warning('请先选择要操作的文件');
      return;
    }
    navigate(`/contents/share`, {
      state: {
        fileList: selectedFileList
          .filter((item) => item.item.fileUrl)
          .map((item) => ({
            fileId: item.item.fileId,
            fileName: item.name,
            fileUrl: item.item?.fileUrl?.replace('&comp=blocklist', '')
          }))
      }
    });
  };

  const goEditDataClassification = () => {
    if (!selectedFileList.length) {
      message.warning('请先选择要操作的文件');
      return;
    }
    updateDataClassification(selectedFileList.map((v) => v.item.fileId!));
  };

  const goEditCategory = () => {
    if (!selectedFileList.length) {
      message.warning('请先选择要操作的文件');
      return;
    }
    navigate(`/contents/edit-category`, {
      state: {
        fileList: selectedFileList
          .filter((item) => item.item.fileUrl)
          .map((item) => ({
            fileId: item.item.fileId,
            fileName: item.name,
            fileUrl: item.item.fileUrl
          }))
      }
    });
  };
  const goEditPermission = () => {
    if (!selectedFileList.length) {
      message.warning('请先选择要操作的文件');
      return;
    }
    navigate(`/contents/edit-permission`, {
      state: {
        fileList: selectedFileList
          .filter((item) => item.item.fileUrl)
          .map((item) => ({
            fileId: item.item.fileId,
            fileName: item.name,
            fileUrl: item.item.fileUrl
          }))
      }
    });
  };
  const goEditTags = () => {
    if (!selectedFileList.length) {
      message.warning('请先选择要操作的文件');
      return;
    }
    navigate(`/contents/edit-tags`, {
      state: {
        fileList: selectedFileList
          .filter((item) => item.item.fileUrl)
          .map((item) => ({
            fileId: item.item.fileId,
            labels: item.item.labels
          }))
      }
    });
  };

  const handleClickMultiple = (value: boolean) => {
    // 点击取消多选，清空选中列表
    setMultiple(value);
    if (!value) {
      setSelectedFileList([]);
    }
  };

  const handleDownLoad = async () => {
    // TODO 下载接口
    const params = selectedFileList
      .filter((item) => item.item.fileUrl)
      .map((item) => ({
        id: item.item.fileId,
        fileName: item.name,
        url: item.item.fileUrl || ''
      }));
    params.forEach((file) => download(file?.fileName, file?.url));
    await downloadRecord({ fileIds: _.compact(params.map((file) => file.id)) });
    await fetchData();
    setSelectedFileList([]);
  };

  const handleUpload = () => {
    // TODO 跳转上传页面
    navigate(`/contents/upload`);
  };

  const handleDeleteInCategory = async () => {
    if (!selectedFileList.length) return;
    try {
      await deleteFileMapping({
        categoryIds: selectedFileList
          .filter((item) => item.item.fileUrl)
          .map((item) => item.id)
      });
      await fetchData({ current: 1 });
      await fetchNavigator?.();
      setDeleteOpen(false);
      setSelectedFileList([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteAll = async () => {
    if (!selectedFileList.length) return;
    try {
      await deleteFile({
        fileIds: _.compact(
          selectedFileList
            .filter((item) => item.item.fileUrl)
            .map((item) => item.item.fileId)
        )
      });
      await fetchData({ current: 1 });
      await fetchNavigator?.();
      setDeleteOpen(false);
      setSelectedFileList([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!selectedFileList.length) {
      message.error('请先选择要删除的文件');
      return;
    }
    setDeleteOpen(newOpen);
  };

  const handleExpand = () => {
    setExpandedRowKeys(addTreeKeyToArray(list));
  };

  const handleCollapse = () => {
    setExpandedRowKeys([]);
  };

  const menuProps: MenuProps['items'] = [
    hasAuth(userInfos.authority, 'update_data_classification')
      ? {
        label: <div onClick={goEditDataClassification}>编辑数据分级</div>,
        key: 'edit-data-classification'
      }
      : null,
    hasAuth(userInfos.authority, 'share')
      ? {
        label: <div onClick={goShare}>分享</div>,
        key: 'share'
      }
      : null,
    hasAuth(userInfos.authority, 'update_category')
      ? {
        label: <div onClick={goEditCategory}>编辑分类</div>,
        key: 'edit-category'
      }
      : null,
    hasAuth(userInfos.authority, 'update_permission')
      ? {
        label: <div onClick={goEditPermission}>编辑权限</div>,
        key: 'edit-permission'
      }
      : null,
    hasAuth(userInfos.authority, 'update_tag')
      ? {
        label: <div onClick={goEditTags}>编辑标签</div>,
        key: 'edit-tag'
      }
      : null
  ];

  const deletePopoverContent = (
    <div className="delete-wrapper">
      <div style={{ marginBottom: 16 }}>确定删除此内容吗</div>
      <div className="delete-buttons-wrapper">
        <div className="delete-buttons">
          <ZeissButton onClick={handleDeleteInCategory} type="primary">
            当前分类中删除
          </ZeissButton>
          <ZeissButton onClick={handleDeleteAll} danger>
            所有分类中删除
          </ZeissButton>
        </div>
        <ZeissButton className="color-btn" onClick={() => setDeleteOpen(false)}>
          取消
        </ZeissButton>
      </div>
    </div>
  );

  const getBreadcrumb = useMemo(() => {
    if (pathname.split('/')[2] === 'new') {
      return [
        { title: <Link to={'/contents/home'}>Home</Link> },
        { title: <span>New</span> }
      ];
    }
    if (pathname.split('/')[2] === 'hot') {
      return [
        { title: <Link to={'/contents/home'}>Home</Link> },
        { title: <span>Hot</span> }
      ];
    }
    if (pathname.split('/')[2] === 'search-result') {
      return;
    }
    const names = breadcrumb?.pathNames.split('@');
    return [{ title: <span>内容列表</span> }].concat(
      breadcrumb?.paths.split('/').map((path, index) => ({
        title: <Link to={`/contents/category/${path}`}>{names?.[index]}</Link>
      })) || []
    );
  }, [breadcrumb, pathname]);

  const getPageTitle = useMemo(() => {
    if (pathname.split('/')[2] === 'new') {
      return 'NEW';
    }
    if (pathname.split('/')[2] === 'hot') {
      return 'HOT';
    }
    if (pathname.split('/')[2] === 'search-result') {
      return '搜索';
    }
    return breadcrumb?.pathNames.split('@').at(-1);
  }, [breadcrumb, pathname]);

  return (
    <div className="content-header">
      <div className="content-breadcrumb">
        <Breadcrumb items={getBreadcrumb} separator=">" />
      </div>
      <div className="content-table-header">
        <div className="page-title">{getPageTitle}</div>
        <div className="content-actions-wrapper">
          {expandedRowKeys.length ? (
            <Tooltip title={'全部收起'}>
              <ZeissButton
                className="color-btn collapse-btn"
                onClick={handleCollapse}
                icon={
                  <Icon className="collapse-icon" component={CollapseIcon} />
                }
              />
            </Tooltip>
          ) : (
            <Tooltip title={'全部展开'}>
              <ZeissButton
                className="color-btn collapse-btn"
                onClick={handleExpand}
                icon={<Icon className="collapse-icon" component={ExpandIcon} />}
              />
            </Tooltip>
          )}
          <Space.Compact>
            <ZeissButton
              icon={
                <MenuOutlined
                  style={{ color: viewType === 'list' ? '#fff' : '#0072EF' }}
                />
              }
              type={viewType === 'list' ? 'primary' : 'default'}
              onClick={() => {
                setViewType('list');
                setSelectedFileList([]);
              }}
              style={{ width: '48px' }}
              className="color-btn"
            />
            <ZeissButton
              icon={
                <Icon
                  component={DashboardIcon}
                  style={{ color: viewType === 'view' ? '#fff' : '#0072EF' }}
                />
              }
              type={viewType === 'view' ? 'primary' : 'default'}
              onClick={() => {
                setViewType('view');
                setSelectedFileList([]);
              }}
              style={{ width: '48px' }}
              className="color-btn"
            />
          </Space.Compact>
          <Divider type="vertical" className="action-divider" />
          {!multiple ? (
            <ZeissButton
              className="color-btn action-button"
              onClick={() => handleClickMultiple(true)}
              icon={<Icon component={ChecklistIcon} />}
            >
              选择
            </ZeissButton>
          ) : (
            <ZeissButton
              className="color-btn action-button"
              onClick={() => handleClickMultiple(false)}
              icon={<CloseOutlined />}
            >
              取消
            </ZeissButton>
          )}
          <Dropdown menu={{ items: menuProps }} trigger={['click']}>
            <ZeissButton
              className="color-btn action-button"
              icon={<Icon component={MoreIcon} />}
            >
              更多
            </ZeissButton>
          </Dropdown>
          {hasAuth(userInfos.authority, 'delete') && (
            <Popover
              title={'提示'}
              content={deletePopoverContent}
              trigger={'click'}
              open={deleteOpen}
              onOpenChange={handleOpenChange}
            >
              <ZeissButton
                className="danger-btn action-button"
                danger
                icon={<Icon component={DeleteIcon} />}
              >
                删除
              </ZeissButton>
            </Popover>
          )}
          {hasAuth(userInfos.authority, 'download') && (
            <ZeissButton
              className="primary-btn action-button"
              type="primary"
              onClick={handleDownLoad}
              icon={<Icon component={DownIcon} />}
            >
              下载
            </ZeissButton>
          )}
          {hasAuth(userInfos.authority, 'upload') && (
            <ZeissButton
              className="primary-btn action-button"
              type="primary"
              onClick={handleUpload}
              icon={<Icon component={UploadIcon} />}
            >
              上传
            </ZeissButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
